import React, { useState, useEffect } from 'react';
import { createContext, useContext } from 'react';

const TriviaContext = createContext();

export const useTriviaContext = () => useContext(TriviaContext);

export const TriviaProvider = ({ children }) => {
    const [roomName, setRoomName] = useState('');
    const [roomToJoin, setRoomToJoin] = useState('');
    const [isHost, setIsHost] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [question, setQuestion] = useState(null);
    const [lastResponseIndex, setLastResponseIndex] = useState(-1);
    const [socket, setSocket] = useState(null);
    const [players, setPlayers] = useState([]);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let intervalId;

        // Start the countdown when setSeconds is greater than 0
        if (seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        // Cleanup the interval when the component unmounts or when seconds reach 0
        return () => clearInterval(intervalId);

    }, [seconds]);

    const contextValue = {
        roomName,
        setRoomName,
        roomToJoin,
        setRoomToJoin,
        isHost,
        setIsHost,
        isConnected,
        setIsConnected,
        question,
        setQuestion,
        lastResponseIndex,
        setLastResponseIndex,
        socket,
        setSocket,
        players,
        setPlayers,
        seconds,
        setSeconds,
    };

    return (
        <TriviaContext.Provider value={contextValue}>
            {children}
        </TriviaContext.Provider>
    );
};
