import { StatusBar } from 'expo-status-bar';
import QRCode from 'react-native-qrcode-svg';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Button, TextInput, TouchableOpacity, Linking } from 'react-native';
import { io } from 'socket.io-client';
import { Table, Row } from 'react-native-table-component';
import { useTriviaContext, TriviaProvider } from './GameContext';

export default function App() {
  return <TriviaProvider>
    <GameRoot />
  </TriviaProvider>;
}

function GameRoot() {
  const appHostUrl = __DEV__ ? 'http://localhost:19006' : `https://trivia.verylazypixels.com`;
  const [hostName] = useState(appHostUrl);

  const {
    roomName,
    setRoomName,
    roomToJoin,
    setRoomToJoin,
    isHost,
    setIsHost,
    isConnected,
    setIsConnected,
    question,
    setQuestion,
    lastResponseIndex,
    setLastResponseIndex,
    socket,
    setSocket,
    players,
    setPlayers,
    seconds,
    setSeconds,
  } = useTriviaContext();

  const handleCreateRoom = () => {
    if (socket) {
      socket.emit('createRoom');
    }
  };

  const handleJoinRoom = () => {
    if (roomToJoin.trim() !== '') {
      setIsHost(false);
      joinRoom(roomToJoin, false);
    }
  };

  const joinRoom = (room, isHost) => {
    socket.emit('joinRoom', { room, isHost });
    // VS: Set the room name only when joined successfully
    setRoomName(room);
  };


  useEffect(() => {
    console.log('Will init app');
    const localUrl = 'ws://localhost:3000';
    const prodUrl = 'wss://trivia-api.verylazypixels.com';
    const socket = io(__DEV__ ? localUrl : prodUrl);

    socket.on('connect', () => {
      console.log('Connected to server');
      setIsConnected(true);
    });

    socket.on('roomCreated', (data) => {
      setRoomName(data.room);
      setIsHost(true);
    });

    socket.on('question', (data) => {
      console.log('Question', data);
      setLastResponseIndex(-1);
      setQuestion(data);
      setSeconds(data.time);
    });

    setSocket(socket);

    return () => {
      console.log('Disconnect');
      socket.disconnect();
      setIsConnected(false);
    };
  }, []);

  const handleReady = () => {
    if (socket) {
      socket.emit('ready', { room: roomName });
    }
  };

  const handleAnswer = (index) => {
    setLastResponseIndex(index);
    if (socket) {
      socket.emit('answer', { index, room: roomName });
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const roomIdFromURL = currentUrl.split('/').pop();
    if (roomIdFromURL && isConnected) {
      console.log(`Will join the room ${roomIdFromURL} automatically`);
      setRoomToJoin(roomIdFromURL);
      joinRoom(roomIdFromURL, false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (socket) {
      socket.on('playerState', setPlayers);
    }

    return () => {
      if (socket) {
        socket.off('playerState', setPlayers);
      }
    };
  }, [socket]);

  const handleLinkPress = () => {
    const url = `${hostName}/${roomName}`;
    Linking.openURL(url);
  };
  const currentPlayerState = players.find((x) => x.id === socket.id);

  return (
    <View style={styles.container}>
      {!roomName ? (
        <>
          <Button title="Host an event" onPress={handleCreateRoom} />
          <br />
          <Text>OR</Text>
          <br />
          <TextInput
            style={styles.input}
            placeholder="Enter event ID"
            value={roomToJoin}
            onChangeText={(text) => setRoomToJoin(text)}
          />
          <Button title="Join" onPress={handleJoinRoom} />
        </>
      ) : isHost ? (
        <>
          <Text>Room:
            <TouchableOpacity onPress={handleLinkPress}>
              <Text style={{ color: 'blue', textDecorationLine: 'underline' }}>
                {roomName}
              </Text>
            </TouchableOpacity>
          </Text>
          <QRCode value={`${hostName}/${roomName}`} />
        </>
      ) : (
        <>
          <Text>You are in the room: {roomName}</Text>
        </>
      )}
      <View>
        {question && (
          <View>
            <Text>Time left: {seconds}</Text>
            <Text>{question.text}</Text>
            {!isHost ?
              question.options.map((option, index) => (
                <Button key={index} title={option} onPress={() => handleAnswer(index)} disabled={lastResponseIndex !== -1} />
              ))
              : <></>}
          </View>
        )}
        {!isHost && roomName?.length > 0 && !currentPlayerState?.isReady && <Button title="Ready" onPress={handleReady} />}
        {isHost && players.length > 0 && (
          <View>
            <Table>
              <Row data={['Name', 'Ready', 'Score']} style={styles.head} textStyle={styles.text} />
              {players.map((player, index) => (
                <Row key={index} data={[player.name, player.isReady ? 'Yes' : 'No', player.score]} textStyle={styles.text} />
              ))}
            </Table>
          </View>
        )}
      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    margin: 10,
    padding: 5,
  },
  head: { height: 40, backgroundColor: '#f1f8ff' },
  text: { margin: 6 },
});
